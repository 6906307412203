import { ClerkApp } from '@clerk/remix'
import { rootAuthLoader } from '@clerk/remix/ssr.server'
import { captureRemixErrorBoundaryError } from '@sentry/remix'

import type { LinksFunction, LoaderFunction } from '@remix-run/node'

import {
	isRouteErrorResponse,
	Links,
	Meta,
	Outlet,
	Scripts,
	ScrollRestoration,
	useParams,
	useRouteError,
} from '@remix-run/react'

import { GlobalLoading } from '~/components/GlobalLoading'
import { Toaster } from '~/components/ui/toaster'

import { ErrorMessage, NotFoundMessage } from './components/ErrorMessage'
import styles from './tailwind.css?url'

export const links: LinksFunction = () => [{ rel: 'stylesheet', href: styles }]

export const loader: LoaderFunction = args => rootAuthLoader(args)

export function Layout({ children }: { children: React.ReactNode }) {
	return (
		<html lang="en" className="h-full">
			<head>
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<link rel="icon" type="image/x-icon" href="/favicon.png" />
				<link
					href="https://cdn.jsdelivr.net/npm/quill@2.0.2/dist/quill.snow.css"
					rel="stylesheet"
				/>
				<Meta />
				<Links />
				<UmamiTracking />
			</head>
			<body className="h-full">
				<GlobalLoading />
				{children}
				<ScrollRestoration getKey={loc => loc.pathname} />
				<Scripts />
			</body>
		</html>
	)
}

function App() {
	const { study_id } = useParams()
	return (
		<div className="min-h-full">
			<Outlet />
			{!study_id && <div className="p-4" />}
			<Toaster />
		</div>
	)
}

function UmamiTracking() {
	return (
		<script
			async
			src={`https://um.doreveal.com/script.js`}
			data-website-id="b7d02fcd-ecbb-4a54-ad52-4b468e3b71d1"
			data-cache="true"
			data-auto-track="true"
			data-domains="doreveal.com,app.doreveal.com"
		></script>
	)
}

export const ErrorBoundary = () => {
	const error = useRouteError()

	if (isRouteErrorResponse(error) && error.status === 404) {
		return <NotFoundMessage />
	}

	captureRemixErrorBoundaryError(error)
	return <ErrorMessage />
}

export default ClerkApp(App)
